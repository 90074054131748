import React, { useState, useEffect } from 'react';
import styles from './AnalysisCriteria.module.css';

const AnalysisCriteria = ({ onClose }) => {
  const [spinsToLookBack, setSpinsToLookBack] = useState(14);
  const [startHighlightingAt, setStartHighlightingAt] = useState(49);
  const [aboveOrBelow, setAboveOrBelow] = useState('Above');
  const [repeatCount, setRepeatCount] = useState(14);
  const [neighborsCount, setNeighborsCount] = useState(6);

  useEffect(() => {
    const savedCriteria = JSON.parse(localStorage.getItem('analysisCriteria'));
    if (savedCriteria) {
      setSpinsToLookBack(parseInt(savedCriteria.spinsToLookBack) || 14);
      setStartHighlightingAt(savedCriteria.startHighlightingAt ? savedCriteria.startHighlightingAt * 100 : 49);
      setAboveOrBelow(savedCriteria.aboveOrBelow || 'Above');
      setRepeatCount(parseInt(savedCriteria.repeatCount) || 14);
      setNeighborsCount(parseInt(savedCriteria.neighborsCount) || 6);
    } else {
      // Set defaults for first-time users
      const defaultCriteria = {
        spinsToLookBack: 14,
        startHighlightingAt: 0.49,
        aboveOrBelow: 'Above',
        repeatCount: 14,
        neighborsCount: 6
      };
      localStorage.setItem('analysisCriteria', JSON.stringify(defaultCriteria));
    }
  }, []);

  const handleSave = () => {
    const criteria = {
      spinsToLookBack: parseInt(spinsToLookBack) || 14,
      startHighlightingAt: startHighlightingAt / 100,
      aboveOrBelow,
      repeatCount: parseInt(repeatCount) || 14,
      neighborsCount: parseInt(neighborsCount) || 6,
    };
    localStorage.setItem('analysisCriteria', JSON.stringify(criteria));
    localStorage.setItem('neighborCount', parseInt(neighborsCount) || 6); // For backwards compatibility
    onClose();
  };

  return (
    <>
      <div className={styles.modalBackdrop} onClick={onClose}></div>
      <div className={styles.modal}>
        <h2 className={styles.header}>Analysis Criteria</h2>
        <label className={styles.label}>
          Spins to look back:
          <input
            type="number"
            value={spinsToLookBack}
            onChange={(e) => setSpinsToLookBack(e.target.value)}
            className={styles.input}
          />
        </label>
        <label className={styles.label}>
          Start highlighting at:
          <input
            type="number"
            value={startHighlightingAt}
            onChange={(e) => setStartHighlightingAt(e.target.value)}
            className={styles.input}
          />
        </label>
        <label className={styles.label}>
          <div className={styles.radioGroup}>
            <label className={styles.radioLabel}>
              <input
                type="radio"
                value="Above"
                checked={aboveOrBelow === 'Above'}
                onChange={(e) => setAboveOrBelow(e.target.value)}
                className={styles.radioInput}
              />
              Above
            </label>
            <label className={styles.radioLabel}>
              <input
                type="radio"
                value="Below"
                checked={aboveOrBelow === 'Below'}
                onChange={(e) => setAboveOrBelow(e.target.value)}
                className={styles.radioInput}
              />
              Below
            </label>
          </div>
        </label>
        
        <h2 className={styles.subHeader}>Unique Repeat Count</h2>
        <label className={styles.label}>
          Repeat Count:
          <input
            type="number"
            value={repeatCount}
            onChange={(e) => setRepeatCount(e.target.value)}
            className={styles.input}
            placeholder="14"
          />
        </label>

        <h2 className={styles.subHeader}>Neighbors</h2>
        <label className={styles.label}>
          Neighbors Count:
          <input
            type="number"
            min="1"
            max="19"
            value={neighborsCount}
            onChange={(e) => setNeighborsCount(e.target.value)}
            className={styles.input}
            placeholder="6"
          />
        </label>

        <div className={styles.buttonContainer}>
          <button className={`${styles.button} ${styles.cancelButton}`} onClick={onClose}>Cancel</button>
          <button className={`${styles.button} ${styles.saveButton}`} onClick={handleSave}>Save</button>
        </div>
      </div>
    </>
  );
};

export default AnalysisCriteria;