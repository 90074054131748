import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSession } from "../context/SessionContext";
import EnterPreviousSpinsModal from "../components/EnterPreviousSpinsModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Settings.css";
import AnalysisCriteria from '../components/AnalysisCriteria';
import { useSpin } from '../context/SpinContext';
import ColorPicker from '../components/ColorPicker';
import MobileSelect from '../components/MobileSelect';

const tabOptions = [
  { value: 'RedBlack', label: 'R/B' },
  { value: 'OddEven', label: 'O/E' },
  { value: 'HighLow', label: 'H/L' },
  { value: 'Dozens', label: 'Doz' },
  { value: 'Columns', label: 'Col' },
  { value: 'Rep', label: 'Rep' },
  { value: 'Neb', label: 'Neb' }
];

const Settings = ({ defaultColor, onColorChange }) => {
  const [sessionDetails, setSessionDetails] = useState({
    name: "",
    location: "",
  });
  const [notes, setNotes] = useState("");
  const [previousSpins, setPreviousSpins] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showAnalysisCriteria, setShowAnalysisCriteria] = useState(false);
  const [defaultScreen, setDefaultScreen] = useState('RedBlack');
  const [showCountBadge, setShowCountBadge] = useState(() => {
    return localStorage.getItem('showCountBadge') === 'true';
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { session, startSession, endSession } = useSession();
  const { fetchSpins, resetSpins } = useSpin();

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (location.state?.showCriteriaModal) {
      setShowAnalysisCriteria(true);
    }
    // Load the default screen from local storage
    const savedDefaultScreen = localStorage.getItem('defaultScreen');
    if (savedDefaultScreen) {
      setDefaultScreen(savedDefaultScreen);
    }
  }, [location.state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSessionDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleDefaultScreenChange = (e) => {
    const selectedScreen = e.target.value;
    setDefaultScreen(selectedScreen);
    localStorage.setItem('defaultScreen', selectedScreen);
  };

  const handleCountBadgeChange = (e) => {
    const checked = e.target.checked;
    setShowCountBadge(checked);
    localStorage.setItem('showCountBadge', checked);
  };

  const handleStartSession = async () => {
    if (!sessionDetails.name) {
      toast.error("Name is required");
      return;
    }
    try {
      const response = await fetch(`${backendUrl}/api/sessions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...sessionDetails, notes }),
      });
      if (response.ok) {
        const data = await response.json();
        startSession(data);
        toast.success("Session started successfully");
        navigate("/settings");
      } else {
        toast.error("Failed to start session");
      }
    } catch (error) {
      toast.error("An error occurred while starting the session");
    }
  };

  const handleEndSession = async () => {
    if (!session) {
      toast.error("No active session to end");
      return;
    }
    try {
      const response = await fetch(`${backendUrl}/api/sessions/end`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ sessionId: session._id, notes }),
      });
      if (response.ok) {
        endSession();
        resetSpins();
        setSessionDetails({ name: "", location: "" });
        setNotes("");
        toast.success("Session ended successfully");
      } else {
        toast.error("Failed to end session");
      }
    } catch (error) {
      toast.error("An error occurred while ending the session");
    }
  };

  const handleAddSpin = (spinValue) => {
    setPreviousSpins([
      ...previousSpins,
      { spin_value: spinValue, outcome: "NB", bet_amt: 0 },
    ]);
  };

  const handleSavePreviousSpins = async () => {
    if (previousSpins.length === 0) return;

    try {
      const response = await fetch(`${backendUrl}/api/spins/bulk`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ session_id: session._id, spins: previousSpins }),
      });

      if (response.ok) {
        setPreviousSpins([]);
        setShowModal(false);
        toast.success("Previous spins saved successfully");
        await fetchSpins(session._id);
        navigate("/settings");
      } else {
        const errorData = await response.json();
        toast.error(`Failed to save previous spins: ${errorData.error}`);
      }
    } catch (error) {
      console.error("Failed to save previous spins:", error);
      toast.error("Failed to save previous spins. Please try again later.");
    }
  };

  const handleEnterPreviousSpins = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="settings-container">
      <ToastContainer position="top-right" autoClose={3000} />
      <h1>Settings</h1>
      {!session ? (
        <div>
          <h2>Start New Session</h2>
          <form className="session-form">
            <div className="form-group">
              <label>Name: </label>
              <input
                type="text"
                name="name"
                value={sessionDetails.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Location: </label>
              <input
                type="text"
                name="location"
                value={sessionDetails.location}
                onChange={handleChange}
              />
            </div>
            
            <div className="form-group">
              <label>Notes: </label>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="notes-textarea"
              ></textarea>
            </div>
            <button
              type="button"
              onClick={handleStartSession}
              className="btn-primary green"
            >
              Start Session
            </button>
          </form>
        </div>
      ) : (
        <div>
          <div>
            <button
              type="button"
              onClick={handleEnterPreviousSpins}
              className="btn-primary"
            >
              Enter Previous Spins
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={() => setShowAnalysisCriteria(true)}
              className="btn-primary purple"
            >
              Analysis Criteria
            </button>
            {showAnalysisCriteria && (
              <AnalysisCriteria onClose={() => setShowAnalysisCriteria(false)} />
            )}
          </div>
          <div>
            <button
              type="button"
              onClick={handleEndSession}
              className="btn-primary red"
            >
              End Session
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={() => navigate('/upload-spins')}
              className="btn-primary"
            >
              Upload Spins
            </button>
          </div>
          <div className="form-group">
            <label>Default Tab Screen:</label>
            <MobileSelect
              value={defaultScreen}
              onChange={(value) => {
                setDefaultScreen(value);
                localStorage.setItem('defaultScreen', value);
              }}
              options={tabOptions}
            />
          </div>
          <div className="settings-option">
            <input
              type="checkbox"
              id="showCountBadge"
              checked={showCountBadge}
              onChange={handleCountBadgeChange}
            />
            <label htmlFor="showCountBadge">
              Show count badge on Rep Page
            </label>
          </div>

          <div className="color-picker">
            <ColorPicker defaultColor={defaultColor} onColorChange={onColorChange} />
          </div>
        </div>
      )}

      {/* Modal for entering previous spins */}
      <EnterPreviousSpinsModal
        show={showModal}
        handleClose={handleCloseModal}
        handleAddSpin={handleAddSpin}
        previousSpins={previousSpins}
        handleSave={handleSavePreviousSpins}
      />
    </div>
  );
};

export default Settings;