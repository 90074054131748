import React, { useState } from 'react';
import styles from './MobileSelect.module.css';

const MobileSelect = ({ value, onChange, options }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  // Find the label for the current value
  const currentLabel = options.find(opt => opt.value === value)?.label || value;

  return (
    <>
      {/* Display field */}
      <div className={styles.selectDisplay} onClick={handleClick}>
        {currentLabel}
        <span className={styles.arrow}>▼</span>
      </div>

      {/* Modal overlay */}
      {isOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <div className={styles.modalHeader}>
              <button className={styles.closeButton} onClick={handleClose}>×</button>
              <h3 className={styles.modalTitle}>Select Default Tab</h3>
            </div>
            <div className={styles.optionsList}>
              {options.map((option) => (
                <button
                  key={option.value}
                  className={`${styles.optionButton} ${option.value === value ? styles.selected : ''}`}
                  onClick={() => handleOptionClick(option.value)}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileSelect;