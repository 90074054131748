import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useSpin } from '../context/SpinContext';
import styles from './UpdateSpinModal.module.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const UpdateSpinModal = ({ show, handleClose, spin, onUpdate, betType }) => {
  const { updateSpin, fetchSpins } = useSpin();
  const [spinValue, setSpinValue] = useState('');
  const [outcome, setOutcome] = useState('');
  const [betAmt, setBetAmt] = useState('');
  const [activeGrid, setActiveGrid] = useState(null);
  const [errors, setErrors] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [changedFields, setChangedFields] = useState({ spinValue: false, betAmt: false });
  const [customBetAmount, setCustomBetAmount] = useState(null);

  const resetValues = useCallback(() => {
    console.log('Spin data:', {
      outcome: spin[`${betType}Outcome`],
      bet: spin[`${betType}Bet`]
    });
    setSpinValue(spin.spin_value);
    let initialBetAmt = '';
    let initialOutcome = '---';
    
    // Handle type-specific outcome and bet amount
    if (betType === 'rep' || betType === 'neighbors') {
      // Only set outcome and amount if there's an existing bet
      if (spin[`${betType}Bet`] && spin[`${betType}Bet`] !== 0) {
        initialOutcome = spin[`${betType}Outcome`] === 'win' ? 'W' :
                        spin[`${betType}Outcome`] === 'loss' ? 'L' : 
                        spin[`${betType}Outcome`];
        initialBetAmt = Math.abs(spin[`${betType}Bet`]).toString();
      }
    } else {
      initialOutcome = spin[`${betType}Outcome`] === 'no bet' ? '---' : 
                      spin[`${betType}Outcome`] === 'win' ? 'W' :
                      spin[`${betType}Outcome`] === 'loss' ? 'L' :
                      spin[`${betType}Outcome`];
      initialBetAmt = (spin[`${betType}Outcome`] === 'no bet' || spin[`${betType}Bet`] === 0) 
        ? '' 
        : spin[`${betType}Bet`].toString();
    }

    setOutcome(initialOutcome);
    
    if (parseInt(initialBetAmt) > 39) {
      setCustomBetAmount(parseInt(initialBetAmt));
      setBetAmt('custom');
    } else {
      setBetAmt(initialBetAmt);
      setCustomBetAmount(null);
    }

    setChangedFields({ spinValue: false, betAmt: false });
    setActiveGrid('bet');
    setErrors({});
  }, [spin, betType]);


  useEffect(() => {
    if (show && spin) {
      resetValues();
    }
  }, [show, spin, betType, resetValues]);

  const handleSpinValueChange = useCallback((value) => {
    setSpinValue(value);
    setChangedFields(prev => ({ ...prev, spinValue: value !== spin.spin_value }));
    setActiveGrid('bet');
  }, [spin.spin_value]);

  const handleCustomBetClick = useCallback(() => {
    // Don't allow bet selection if outcome isn't selected
    if (!outcome || outcome === '---') {
      setErrors({ outcome: "Please select Win or Loss first" });
      return;
    }

    const customBet = prompt('Enter custom bet amount:', customBetAmount?.toString() || '');
    if (customBet && !isNaN(customBet) && Number(customBet) > 0) {
      setCustomBetAmount(Number(customBet));
      setBetAmt('custom');
      setChangedFields(prev => ({ ...prev, betAmt: true }));
      setErrors(prev => ({ ...prev, betAmount: null }));
    } else if (customBet !== null) {
      alert('Please enter a valid number greater than 0.');
    }
  }, [customBetAmount, outcome]);

  const handleBetAmount = useCallback((amount) => {
    // Don't allow bet selection if outcome isn't selected
    if (!outcome || outcome === '---') {
      setErrors({ outcome: "Please select Win or Loss first" });
      return;
    }

    if (amount === 'custom') {
      handleCustomBetClick();
    } else {
      setBetAmt(amount);
      setChangedFields(prev => ({ ...prev, betAmt: amount !== spin[`${betType}Bet`].toString() }));
      setCustomBetAmount(null);
    }
    setErrors(prev => ({ ...prev, betAmount: null }));
  }, [betType, spin, handleCustomBetClick, outcome]);

  const handleOutcomeChange = useCallback((newOutcome) => {
    if (newOutcome === 'NB') {
      setBetAmt('0');
      setOutcome('---');
      setActiveGrid(null);
    } else {
      setOutcome(newOutcome);
      if (betAmt === '0') {
        setBetAmt('');
      }
      setActiveGrid('bet');
    }
    setErrors({});  // Clear any existing errors
    setChangedFields(prev => ({ ...prev, betAmt: true }));
  }, [betAmt]);

  const isValidBet = useMemo(() => {
    // No bet is valid only if outcome is explicitly set to '---'
    if (outcome === '---') return true;
    // Win/Loss requires both valid outcome and bet amount
    if (outcome === 'W' || outcome === 'L') {
      const betAmount = betAmt === 'custom' ? customBetAmount : parseFloat(betAmt);
      return betAmount > 0;
    }
    // If outcome isn't set or is invalid, the bet is invalid
    return false;
  }, [outcome, betAmt, customBetAmount]);

  const handleSave = useCallback(async () => {
    if (!outcome || (outcome !== '---' && outcome !== 'W' && outcome !== 'L')) {
      setErrors(prev => ({ ...prev, outcome: "Please select Win, Loss, or No Bet" }));
      return;
    }
    if (!isValidBet) {
      setErrors(prev => ({ ...prev, betAmount: "Bet amount must be greater than 0 for Win or Loss" }));
      return;
    }

    try {
      const updateData = {
        spinId: spin._id,
        spin_value: spinValue,
      };

      // Get the raw bet amount (either from custom or regular bet)
      const rawBetAmount = betAmt === 'custom' ? customBetAmount : parseFloat(betAmt) || 0;

      // For rep and neighbors, adjust the bet sign based on outcome
      if (betType === 'rep' || betType === 'neighbors') {
        // For Loss, make the bet amount negative
        const finalBetAmount = outcome === 'L' ? -Math.abs(rawBetAmount) : Math.abs(rawBetAmount);
        updateData[`${betType}Outcome`] = outcome;
        updateData[`${betType}Bet`] = outcome === '---' ? 0 : finalBetAmount;
      } else {
        // For other bet types, keep the original logic
        updateData[`${betType}Outcome`] = outcome;
        updateData[`${betType}Bet`] = outcome === '---' ? 0 : rawBetAmount;
      }

      const response = await axios.put(`${backendUrl}/api/spins/${spin._id}`, updateData);

      if (response.status === 200) {
        updateSpin(response.data);
        await fetchSpins(spin.session_id);
        handleClose();
        if (onUpdate) {
          onUpdate(response.data);
        }
      }
    } catch (error) {
      console.error("Failed to update spin:", error);
      setErrors(prev => ({ ...prev, submit: "Failed to update spin. Please try again." }));
    }
  }, [spin._id, spin.session_id, spinValue, betType, outcome, betAmt, customBetAmount, updateSpin, fetchSpins, handleClose, onUpdate, isValidBet]);

  const handleCancel = useCallback(() => {
    resetValues();
    handleClose();
  }, [resetValues, handleClose]);

  const handleDelete = useCallback(() => {
    setShowDeleteConfirmation(true);
  }, []);

  const confirmDelete = useCallback(async () => {
    try {
      await axios.delete(`${backendUrl}/api/spins/${spin._id}`);
      await fetchSpins(spin.session_id);
      handleClose();
    } catch (error) {
      console.error("Failed to delete spin:", error);
      setErrors(prev => ({ ...prev, delete: "Failed to delete spin. Please try again." }));
    }
    setShowDeleteConfirmation(false);
  }, [spin._id, spin.session_id, fetchSpins, handleClose]);

  const cancelDelete = useCallback(() => {
    setShowDeleteConfirmation(false);
  }, []);

  const toggleGrid = useCallback((gridType) => {
    setActiveGrid(gridType);
  }, []);

  const getSpinButtonColor = useCallback((value) => {
    if (value === '0' || value === '00') return styles.greenButton;
    const redNumbers = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];
    return redNumbers.includes(parseInt(value)) ? styles.redButton : styles.blackButton;
  }, []);

  const getBetButtonClass = useCallback((value) => {
    let classes = [styles.betButton];
    if (betAmt === value) classes.push(styles.selected);
    if (outcome === 'L') classes.push(styles.redButton);
    return classes.join(' ');
  }, [betAmt, outcome]);

  return (
    show && (
      <div className={styles["edit-spin-modal"]}>
        <div className={styles["edit-spin-content"]}>
          <div className={styles["scrollable-content"]}>
            <div className={styles["modal-header"]}>
              <h3>Edit Spin</h3>
              <span className={styles["original-values"]}>{`${spin.spin_value} / ${spin[`${betType}Outcome`] || '---'} / ${Math.abs(spin[`${betType}Bet`]) || '---'}`}</span>
            </div>
            
            {/* Outcome Selection */}
            <div className={styles["outcome-buttons-container"]}>
              <button
                className={`${styles["outcome-button"]} ${outcome === 'W' ? styles["selected-win"] : ''}`}
                onClick={() => handleOutcomeChange('W')}
              >
                Win
              </button>
              <button
                className={`${styles["outcome-button"]} ${outcome === 'L' ? styles["selected-loss"] : ''}`}
                onClick={() => handleOutcomeChange('L')}
              >
                Loss
              </button>
              <button
                className={`${styles["outcome-button"]} ${outcome === '---' ? styles["selected-nb"] : ''}`}
                onClick={() => handleOutcomeChange('NB')}
              >
                No Bet
              </button>
            </div>

            {errors.outcome && <div className={styles["error-message"]}>{errors.outcome}</div>}
            
            {/* Bet Amount Grid */}
            {outcome && outcome !== '---' && (
              <div className={styles["grid-container"]}>
                <div className={styles["grid"]}>
                  {[...Array(39)].map((_, i) => (
                    <button
                      key={i + 1}
                      className={getBetButtonClass((i + 1).toString())}
                      onClick={() => handleBetAmount((i + 1).toString())}
                    >
                      {i + 1}
                    </button>
                  ))}
                  <button
                    className={getBetButtonClass('custom')}
                    onClick={() => handleBetAmount('custom')}
                  >
                    {customBetAmount !== null ? `${customBetAmount}` : '$$'}
                  </button>
                </div>
              </div>
            )}

            {errors.betAmount && <div className={styles["error-message"]}>{errors.betAmount}</div>}
          </div>

          <div className={styles["button-group"]}>
            <button className={styles["btn-cancel"]} onClick={handleClose}>
              Cancel
            </button>
            <button 
              className={styles["btn-update"]} 
              onClick={handleSave}
              disabled={!isValidBet}
            >
              Save
            </button>
            <button className={styles["btn-delete"]} onClick={handleDelete} aria-label="Delete">
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        </div>

        {showDeleteConfirmation && (
          <div className={styles["delete-confirmation-modal"]}>
            <div className={styles["delete-confirmation-content"]}>
              <p>Are you sure you want to delete this spin?</p>
              <div className={styles["delete-confirmation-buttons"]}>
                <button onClick={confirmDelete} className={styles["btn-confirm-delete"]}>Yes, Delete</button>
                <button onClick={cancelDelete} className={styles["btn-cancel-delete"]}>No, Cancel</button>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default UpdateSpinModal;