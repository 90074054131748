import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSession } from "../context/SessionContext";
import { useSpin } from "../context/SpinContext"; // Correct path to SpinContext
import "./Spin.css";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const BET_OPTIONS_COUNT = 39;

const Spin = () => {
  const [customBetAmount, setCustomBetAmount] = useState(null);
  const [currentStrategy, setCurrentStrategy] = useState("");
  const { session } = useSession();
  const { fetchSpins } = useSpin();
  const navigate = useNavigate();
  const [spinData, setSpinData] = useState({
    session_id: "",
    spin_value: "",
    rbOutcome: "---",
    oeOutcome: "---",
    hlOutcome: "---",
    dozOutcome: "---",
    colOutcome: "---",
    repOutcome: "---",
    neighborsOutcome: "---",
    rbBet: 0,
    oeBet: 0,
    hlBet: 0,
    dozBet: 0,
    colBet: 0,
    repBet: 0,
    neighborsBet: 0
  });

  const [isStrategySelected, setIsStrategySelected] = useState(false);
  const [summaryVisible, setSummaryVisible] = useState(false);

  useEffect(() => {
    if (session) {
      setSpinData((prevData) => ({ ...prevData, session_id: session._id }));
    }
  }, [session]);

  const handleStrategyChange = (strategy) => {
    setCurrentStrategy(strategy);
    setIsStrategySelected(true);
    
    // Automatically set loss for zeros in standard strategies
    if ((spinData.spin_value === "0" || spinData.spin_value === "00") && 
        strategy !== "rep" && 
        strategy !== "neighbors") {
      setSpinData(prevData => ({
        ...prevData,
        [`${strategy}Outcome`]: "loss"
      }));
    }
  };

  const handleSpinValueChange = (value) => {
    setSpinData((prevData) => ({ ...prevData, spin_value: value }));
    setCurrentStrategy("");
    setIsStrategySelected(false);
  };

  const handleOutcomeChange = (outcome) => {
    setSpinData((prevData) => {
      const currentOutcome = prevData[`${currentStrategy}Outcome`];
      return {
        ...prevData,
        [`${currentStrategy}Outcome`]:
          currentOutcome === outcome ? "---" : outcome,
        [`${currentStrategy}Bet`]:
          currentOutcome === outcome ? 0 : prevData[`${currentStrategy}Bet`],
      };
    });
  };

  const handleBetAmount = (bet) => {
    if (bet === "custom") {
      return; // Custom bet amount is handled separately
    }
  
    setSpinData((prevData) => {
      const currentBet = prevData[`${currentStrategy}Bet`];
      return {
        ...prevData,
        [`${currentStrategy}Bet`]: currentBet === bet ? 0 : bet,
      };
    });
    setSummaryVisible(true);
  };

  const handleNoBet = async () => {
    setSpinData((prevData) => ({
      ...prevData,
      rbOutcome: "---",
      oeOutcome: "---",
      hlOutcome: "---",
      dozOutcome: "---",
      colOutcome: "---",
      repOutcome: "---",
      neighborsOutcome: "---",
      rbBet: 0,
      oeBet: 0,
      hlBet: 0,
      dozBet: 0,
      colBet: 0,
      repBet: 0,
      neighborsBet: 0,
    }));
    setSummaryVisible(false);

    // Save immediately when No Bet is selected
    try {
      const response = await fetch(`${backendUrl}/api/spins`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...spinData,
          rbOutcome: "---",
          oeOutcome: "---",
          hlOutcome: "---",
          dozOutcome: "---",
          colOutcome: "---",
          repOutcome: "---",
          neighborsOutcome: "---",
          rbBet: 0,
          oeBet: 0,
          hlBet: 0,
          dozBet: 0,
          colBet: 0,
          repBet: 0,
          neighborsBet: 0,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to save spin");
      }

      const savedSpin = await response.json();
      console.log("Saved data:", savedSpin);
      await fetchSpins(spinData.session_id); // Fetch spins after adding a new one
      resetForm();
      navigateToDefaultScreen();
    } catch (error) {
      console.error("Error saving spin:", error);
    }
  };

  const handleSave = async () => {
    if (!spinData.spin_value) {
      alert("Please select a spin value");
      return;
    }

    const transformedSpinData = {
      ...spinData,
      // Transform outcomes to W/L format
      rbOutcome: spinData.rbOutcome === "win" ? "W" : spinData.rbOutcome === "loss" ? "L" : spinData.rbOutcome,
      oeOutcome: spinData.oeOutcome === "win" ? "W" : spinData.oeOutcome === "loss" ? "L" : spinData.oeOutcome,
      hlOutcome: spinData.hlOutcome === "win" ? "W" : spinData.hlOutcome === "loss" ? "L" : spinData.hlOutcome,
      dozOutcome: spinData.dozOutcome === "win" ? "W" : spinData.dozOutcome === "loss" ? "L" : spinData.dozOutcome,
      colOutcome: spinData.colOutcome === "win" ? "W" : spinData.colOutcome === "loss" ? "L" : spinData.colOutcome,
      repOutcome: spinData.repOutcome === "win" ? "W" : spinData.repOutcome === "loss" ? "L" : spinData.repOutcome,
      neighborsOutcome: spinData.neighborsOutcome === "win" ? "W" : spinData.neighborsOutcome === "loss" ? "L" : spinData.neighborsOutcome,
    };

    try {
      const response = await fetch(`${backendUrl}/api/spins`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(transformedSpinData),
      });

      if (!response.ok) {
        throw new Error("Failed to save spin");
      }

      const savedSpin = await response.json();
      console.log("Saved data:", savedSpin);
      await fetchSpins(spinData.session_id); // Fetch spins after adding a new one
      resetForm();
      navigateToDefaultScreen();
    } catch (error) {
      console.error("Error saving spin:", error);
    }
  };

  const resetForm = () => {
    setSpinData({
      session_id: session ? session._id : "",
      spin_value: "",
      rbOutcome: "---",
      oeOutcome: "---",
      hlOutcome: "---",
      dozOutcome: "---",
      colOutcome: "---",
      neighborsOutcome: "---",
      rbBet: 0,
      oeBet: 0,
      hlBet: 0,
      dozBet: 0,
      colBet: 0,
      neighborsBet: 0,
    });
    setCurrentStrategy("");
    setIsStrategySelected(false);
    setSummaryVisible(false);
  };

  const handleCustomBetClick = () => {
    const customBet = prompt('Enter custom bet amount:');
    if (customBet && !isNaN(customBet) && Number(customBet) > 0) {
      setCustomBetAmount(Number(customBet)); // Set custom bet amount
      setSpinData((prevData) => ({
        ...prevData,
        [`${currentStrategy}Bet`]: Number(customBet), // Set custom bet value
      }));
      setSummaryVisible(true); // Show summary after setting custom bet
      setIsStrategySelected(true); // Ensure strategy is selected to show buttons
    } else {
      alert('Please enter a valid number.');
    }
  };

  const navigateToDefaultScreen = () => {
    // If there was a bet, navigate to that strategy's tab
    if (spinData[`${currentStrategy}Bet`]) {
      const strategyToTabMap = {
        'rb': 'RedBlack',
        'oe': 'OddEven',
        'hl': 'HighLow',
        'doz': 'Dozens',
        'col': 'Columns',
        'rep': 'Rep',
        'neighbors': 'Neb'
      };
      navigate(`/stats?tab=${strategyToTabMap[currentStrategy]}`);
    } else {
      // If no bet was placed, use the default screen setting
      const defaultScreen = localStorage.getItem("defaultScreen") || "RedBlack";
      navigate(`/stats?tab=${defaultScreen}`);
    }
  };

  const handleBack = () => {
    resetForm();
  };

  const getColorClass = (spinValue) => {
    const redNumbers = [
      "1",
      "3",
      "5",
      "7",
      "9",
      "12",
      "14",
      "16",
      "18",
      "19",
      "21",
      "23",
      "25",
      "27",
      "30",
      "32",
      "34",
      "36",
    ];
    const blackNumbers = [
      "2",
      "4",
      "6",
      "8",
      "10",
      "11",
      "13",
      "15",
      "17",
      "20",
      "22",
      "24",
      "26",
      "28",
      "29",
      "31",
      "33",
      "35",
    ];
    if (spinValue === "0" || spinValue === "00") return "green";
    if (redNumbers.includes(spinValue)) return "red";
    if (blackNumbers.includes(spinValue)) return "black";
    return "";
  };


  const getSummaryText = () => {
    const getOutcomeText = (outcome, bet, prefix) => {
      if (!outcome || outcome === "---" || outcome === "No Bet") return "";
      return `<b>${prefix}</b> (${outcome.charAt(0).toUpperCase()}) ${
        bet === 'custom' ? customBetAmount : bet
      }`;
    };

    if (
      spinData.rbOutcome === "No Bet" &&
      spinData.oeOutcome === "No Bet" &&
      spinData.hlOutcome === "No Bet" &&
      spinData.dozOutcome === "No Bet" &&
      spinData.colOutcome === "No Bet" &&
      spinData.repOutcome === "No Bet" &&
      spinData.neighborsOutcome === "No Bet"
    ) {
      return "No Bet";
    }

    const texts = [
      getOutcomeText(spinData.rbOutcome, spinData.rbBet, "R/B"),
      getOutcomeText(spinData.oeOutcome, spinData.oeBet, "O/E"),
      getOutcomeText(spinData.hlOutcome, spinData.hlBet, "H/L"),
      getOutcomeText(spinData.dozOutcome, spinData.dozBet, "Doz"),
      getOutcomeText(spinData.colOutcome, spinData.colBet, "Col"),
      getOutcomeText(spinData.repOutcome, spinData.repBet, "Rep"),
      getOutcomeText(spinData.neighborsOutcome, spinData.neighborsBet, "Neighbors")
    ];

    return texts.filter(text => text).join(", ");
  };

  return (
    <div className="spin-page">
      {spinData.spin_value && (
        <button className="back-button" onClick={handleBack}>
          Back
        </button>
      )}

      {!spinData.spin_value && (
        <>
          <div className="zeros-container">
            <div className="first-row-button-container">
              <button
                key="0"
                className={`spin-button first-row-button ${getColorClass("0")}`}
                onClick={() => handleSpinValueChange("0")}
              >
                <span>0</span>
              </button>
            </div>
            <div className="first-row-button-container">
              <button
                key="00"
                className={`spin-button first-row-button ${getColorClass("00")}`}
                onClick={() => handleSpinValueChange("00")}
              >
                <span>00</span>
              </button>
            </div>
          </div>

          <div className="other-numbers-container">
            {Array.from({ length: 36 }, (_, i) => (i + 1).toString()).map(
              (number, index) => (
                <React.Fragment key={number}>
                  <button
                    className={`spin-button ${getColorClass(number)}`}
                    onClick={() => handleSpinValueChange(number)}
                  >
                    <span>{number}</span>
                  </button>
                  {(index + 1) % 12 === 0 && index !== 35 && (
                    <hr className="divider" />
                  )}
                </React.Fragment>
              )
            )}
          </div>
        </>
      )}

      {spinData.spin_value && (
        <>
          <div className="selected-spin-container">
            <button
              className={`selected-spin ${getColorClass(spinData.spin_value)}`}
            >
              <span>{spinData.spin_value}</span>
            </button>
          </div>
          <button onClick={handleNoBet} className="no-bet-button">
            No Bet
          </button>
          <div className="strategy-buttons">
            <button
              onClick={() => handleStrategyChange("rb")}
              className={`strategy-button ${currentStrategy === "rb" ? "active" : ""}`}
            >
              R/B
            </button>
            <button
              onClick={() => handleStrategyChange("oe")}
              className={`strategy-button ${currentStrategy === "oe" ? "active" : ""}`}
            >
              O/E
            </button>
            <button
              onClick={() => handleStrategyChange("hl")}
              className={`strategy-button ${currentStrategy === "hl" ? "active" : ""}`}
            >
              H/L
            </button>
            <button
              onClick={() => handleStrategyChange("doz")}
              className={`strategy-button ${currentStrategy === "doz" ? "active" : ""}`}
            >
              Doz
            </button>
            <button
              onClick={() => handleStrategyChange("col")}
              className={`strategy-button ${currentStrategy === "col" ? "active" : ""}`}
            >
              Col
            </button>
            <button
              onClick={() => handleStrategyChange("rep")}
              className={`strategy-button ${currentStrategy === "rep" ? "active" : ""}`}
            >
              Rep
            </button>
            <button
              onClick={() => handleStrategyChange("neighbors")}
              className={`strategy-button ${currentStrategy === "neighbors" ? "active" : ""}`}
            >
              Neighbors
            </button>
          </div>
          {isStrategySelected && currentStrategy !== "nb" && (
  <div className="outcome-buttons-container">
    <button
      onClick={() => handleOutcomeChange("win")}
      className={`outcome-button win-button ${
        spinData[`${currentStrategy}Outcome`] === "win" ? "outlined" : ""
      }`}
      disabled={
        (spinData.spin_value === "0" || spinData.spin_value === "00") && 
        currentStrategy !== "rep" && 
        currentStrategy !== "neighbors"
      }
    >
      Win
    </button>
    <button
      onClick={() => handleOutcomeChange("loss")}
      className={`outcome-button loss-button ${
        spinData[`${currentStrategy}Outcome`] === "loss" ? "outlined" : ""
      }`}
    >
      Loss
    </button>
  </div>
)}
{isStrategySelected &&
  currentStrategy !== "nb" &&
  spinData[currentStrategy + "Outcome"] &&
  spinData[currentStrategy + "Outcome"] !== "---" && (
    <div className="bet-amount-container">
      <div className="bet-grid">
        {[...Array(BET_OPTIONS_COUNT)].map((_, i) => (
          <button
            key={i + 1}
            className={`bet-button ${
              spinData[currentStrategy + 'Outcome'] === 'win' ? 'green' : 'red'
            } ${
              spinData[currentStrategy + 'Bet'] === i + 1 ? 'highlighted' : ''
            }`}
            onClick={() => handleBetAmount(i + 1)}
          >
            {i + 1}
          </button>
        ))}
        <button
          className={`bet-button custom-bet-button ${
            spinData[currentStrategy + 'Outcome'] === 'win' ? 'green' : 'red'
          } ${spinData[currentStrategy + 'Bet'] === 'custom' ? 'highlighted' : ''}`}
          onClick={handleCustomBetClick}
        >
          {customBetAmount !== null ? `${customBetAmount}` : '$$'}
        </button>
      </div>
    </div>
  )}


          {summaryVisible && (
            <div className="summary-text">
              <span dangerouslySetInnerHTML={{ __html: getSummaryText() }} />
            </div>
          )}
          {isStrategySelected &&
            currentStrategy !== "nb" &&
            spinData[currentStrategy + "Bet"] > 0 && (
              <div className="action-buttons">
                <button className="cancel-button" onClick={resetForm}>
                  Cancel
                </button>
                <button className="save-button" onClick={handleSave}>
                  Save
                </button>
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default Spin;