import React, { useEffect, useState, useMemo } from "react";
import { useSession } from "../context/SessionContext";
import { useSpin } from "../context/SpinContext";
import styles from './Rep.module.css';
import UpdateSpinModal from '../components/UpdateSpinModal';

const Rep = () => {
  const { session } = useSession();
  const { state: { spins, spinsFetched }, fetchSpins, updateSpin, deleteSpin } = useSpin();
  const [loading, setLoading] = useState(false);
  const [targetUniqueSpins, setTargetUniqueSpins] = useState(14);
  const [showModal, setShowModal] = useState(false);
  const [currentSpin, setCurrentSpin] = useState(null);
  const [showCountBadge, setShowCountBadge] = useState(false);

  useEffect(() => {
    const savedCriteria = JSON.parse(localStorage.getItem('analysisCriteria'));
    if (savedCriteria && savedCriteria.repeatCount) {
      setTargetUniqueSpins(savedCriteria.repeatCount);
    }

    const showBadge = localStorage.getItem('showCountBadge') === 'true';
    setShowCountBadge(showBadge);
  }, []);

  useEffect(() => {
    if (session?._id && !loading && !spinsFetched) {
      setLoading(true);
      fetchSpins(session._id)
        .catch(error => {
          console.error("Error fetching spins:", error);
        })
        .finally(() => setLoading(false));
    }
  }, [session?._id, loading, spinsFetched, fetchSpins]);

  const processedSpins = useMemo(() => {
    if (!spins.length) return [];
    
    let result = [];
    let runningTotal = 0;
    
    spins.forEach((spin, index) => {
      const spinValue = spin.spin_value;
      let actualOutcome = '---';
      
      if (index > 0) {
        const previousSpins = spins.slice(0, index);
        const seenNumbers = new Set();
        const lastUniqueNumbers = previousSpins
          .reverse()
          .filter(prevSpin => {
            if (!seenNumbers.has(prevSpin.spin_value)) {
              seenNumbers.add(prevSpin.spin_value);
              return true;
            }
            return false;
          })
          .reverse()
          .slice(-targetUniqueSpins)
          .map(s => s.spin_value);
  
        if (lastUniqueNumbers.length === targetUniqueSpins) {
          actualOutcome = lastUniqueNumbers.includes(spinValue) ? 'W' : 'L';
        }
  
        // Calculate display bet amount and running total
        const betAmount = spin.repBet || 0;
        let displayBet = 0;
        
        if (betAmount !== 0 && spin.repOutcome) {
          displayBet = spin.repOutcome === 'W' ? Math.abs(betAmount) : -Math.abs(betAmount);
          runningTotal += displayBet;
        }
  
        result.push({
          ...spin,
          calculatedOutcome: actualOutcome,
          displayBet,
          running_total: runningTotal
        });
      } else {
        // First spin
        result.push({
          ...spin,
          calculatedOutcome: actualOutcome,
          displayBet: 0,
          running_total: 0
        });
      }
    });
  
    return result;
  }, [spins, targetUniqueSpins]);

  const uniqueSpins = useMemo(() => {
    const seen = new Set();
    const lastUnique = [];
    
    for (let i = processedSpins.length - 1; i >= 0; i--) {
      const spinValue = processedSpins[i].spin_value;
      if (!seen.has(spinValue)) {
        seen.add(spinValue);
        lastUnique.push(processedSpins[i]);
        if (lastUnique.length === targetUniqueSpins) break;
      }
    }
    return lastUnique;
  }, [processedSpins, targetUniqueSpins]);

  const handleEditSpin = (spin) => {
    setCurrentSpin({
      ...spin,
      repOutcome: spin.repOutcome 
    });
    setShowModal(true);
  };

  const handleUpdateSpin = (updatedSpin) => {
    updateSpin(updatedSpin);
    setShowModal(false);
  };

  const handleDeleteSpin = (deletedSpin) => {
    deleteSpin(deletedSpin);
    setShowModal(false);
  };

  const getColor = useMemo(() => {
    return (spinValue) => {
      const redNumbers = ['1', '3', '5', '7', '9', '12', '14', '16', '18', '19', '21', '23', '25', '27', '30', '32', '34', '36'];
      const blackNumbers = ['2', '4', '6', '8', '10', '11', '13', '15', '17', '20', '22', '24', '26', '28', '29', '31', '33', '35'];
      if (spinValue === '0' || spinValue === '00') return 'green';
      if (redNumbers.includes(spinValue)) return 'red';
      if (blackNumbers.includes(spinValue)) return 'black';
      return '';
    };
  }, []);

  const renderRouletteGrid = () => {
    const numbers = Array.from({ length: 36 }, (_, i) => i + 1);
    const rows = [];
    
    for (let i = 0; i < numbers.length; i += 3) {
      const rowNumbers = numbers.slice(i, i + 3);
      rows.push(
        <React.Fragment key={i}>
          <div className={styles.gridRow}>
            {rowNumbers.map(number => {
              const isUnique = uniqueSpins.some(spin => spin.spin_value === number.toString());
              const occurrences = processedSpins.filter(spin => spin.spin_value === number.toString()).length;
              
              return (
                <div 
                  key={number}
                  className={`${styles.rouletteNumber} ${styles[getColor(number.toString())]} ${
                    uniqueSpins.length === targetUniqueSpins && isUnique ? styles.highlighted : ''
                  }`}
                >
                  {number}
                  {showCountBadge && occurrences > 0 && (
                    <span className={styles.count}>{occurrences}</span>
                  )}
                </div>
              );
            })}
          </div>
          {rowNumbers[rowNumbers.length - 1] === 12 || rowNumbers[rowNumbers.length - 1] === 24 ? <div className={styles.horizontalDivider} /> : null}
        </React.Fragment>
      );
    }
    
    return rows;
  };

  const hasTargetUniques = uniqueSpins.length === targetUniqueSpins;

  if (loading) return <div>Loading...</div>;

  return (
    <div className={styles.historyTab}>
      <div className={styles.historyContent}>
        <div className={styles.spinHistoryGrid} style={{ paddingBottom: '75px' }}>
          <div className={styles.spinHistoryHeader}>
            <div className={styles.headerNo}>No.</div>
            <div className={styles.headerSpin}>Spin</div>
            <div className={styles.headerWl}>W/L</div>
            <div className={styles.headerBet}>Bet</div>
            <div className={styles.headerRt}>RT</div>
          </div>
          {processedSpins.map((spin, index) => (
            <div key={spin._id} className={styles.spinRecord} onClick={() => handleEditSpin(spin)}>
              <div className={styles.spinNumber}>{index + 1}</div>
              <div className={`${styles.spinValue} ${styles[getColor(spin.spin_value)]}`}>
                {spin.spin_value}
              </div>
              <div className={`${styles.outcome} ${
                spin.calculatedOutcome === 'W' ? styles.win :
                spin.calculatedOutcome === 'L' ? styles.loss :
                ''
              }`}>
                {spin.calculatedOutcome}
              </div>
              <div className={styles.betAmount}>
                {!spin.repBet || !spin.repOutcome || spin.repOutcome === '---'
                  ? '---'
                  : spin.displayBet.toString()
                }
              </div>
              <div className={styles.runningTotal}>{spin.running_total}</div>
            </div>
          ))}
        </div>
      </div>
      
      <div className={styles.rouletteContainer}>
        <div className={`${styles.rouletteHeader} ${hasTargetUniques ? styles.headerHighlight : ''}`}>
          Unique #s: {uniqueSpins.length}/{targetUniqueSpins}
        </div>
        <div className={styles.rouletteTable}>
          <div className={styles.zerosRow}>
            <div className={`${styles.rouletteNumber} ${styles.green} ${
              uniqueSpins.length === targetUniqueSpins && 
              uniqueSpins.some(spin => spin.spin_value === '0') ? 
              styles.highlighted : ''
            }`}>
              0
              {showCountBadge && processedSpins.filter(spin => spin.spin_value === '0').length > 0 && (
                <span className={styles.count}>
                  {processedSpins.filter(spin => spin.spin_value === '0').length}
                </span>
              )}
            </div>
            <div className={`${styles.rouletteNumber} ${styles.green} ${
              uniqueSpins.length === targetUniqueSpins && 
              uniqueSpins.some(spin => spin.spin_value === '00') ? 
              styles.highlighted : ''
            }`}>
              00
              {showCountBadge && processedSpins.filter(spin => spin.spin_value === '00').length > 0 && (
                <span className={styles.count}>
                  {processedSpins.filter(spin => spin.spin_value === '00').length}
                </span>
              )}
            </div>
          </div>
          <div className={styles.numbersGrid}>
            {renderRouletteGrid()}
          </div>
        </div>
        <div className={styles.rouletteNumbers}>
          {uniqueSpins.slice().reverse().map((spin, index) => (
            <div 
              key={index} 
              className={`${styles.orderNumber} ${styles[getColor(spin.spin_value)]}`}
            >
              {spin.spin_value}
            </div>
          ))}
        </div>
      </div>

      {currentSpin && (
        <UpdateSpinModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          spin={currentSpin}
          onUpdate={handleUpdateSpin}
          onDelete={handleDeleteSpin}
          betType="rep"
        />
      )}
    </div>
  );};

export default Rep;